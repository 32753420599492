import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Input/Numeric.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "relative"
};
const _hoisted_2 = { class: "flex flex-wrap" };
const _hoisted_3 = { class: "px-4 w-full" };
const _hoisted_4 = { class: "text-center" };
const _hoisted_5 = { class: "flex items-center justify-center" };
const _hoisted_6 = { class: "uppercase text-xs font-semibold text-gray-200" };
const _hoisted_7 = { class: "mt-4 text-center" };
const _hoisted_8 = {
  class: "font-mono flex items-center justify-center text-gray-200 text-base lg:text-xl",
  "data-cy": "add-margin-modal-available-text-content"
};
const _hoisted_9 = { class: "text-gray-500 ml-2" };
const _hoisted_10 = { class: "mt-4" };
const _hoisted_11 = { class: "flex flex-wrap" };
const _hoisted_12 = { class: "w-full" };
const _hoisted_13 = {
  key: 0,
  class: "capitalize-phrase text-red-500 text-sm mt-2"
};
const _hoisted_14 = { class: "w-full mt-6 text-center" };
import {
  Status,
  StatusType,
  BigNumberInWei,
  BigNumberInBase
} from "@injectivelabs/utils";
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BusEvents, Modal } from "@/types";
import { UI_DEFAULT_PRICE_DISPLAY_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "AddMargin",
  setup(__props) {
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { t } = useLang();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const { handleSubmit, resetForm } = useForm();
    const status = reactive(new Status(StatusType.Idle));
    const position = ref(void 0);
    const isModalOpen = computed(
      () => modalStore.modals[Modal.AddMarginToPosition] && !!position.value
    );
    const market = computed(() => {
      if (!position.value) {
        return;
      }
      return derivativeStore.markets.find(
        (market2) => {
          var _a;
          return market2.marketId === ((_a = position.value) == null ? void 0 : _a.marketId);
        }
      );
    });
    const quoteBalance = computed(() => {
      if (!market.value) {
        return ZERO_IN_BASE;
      }
      const quoteToken = market.value.quoteToken;
      return new BigNumberInWei(
        bankStore.balanceMap[quoteToken.denom] || "0"
      ).toBase(quoteToken.decimals);
    });
    onMounted(() => {
      useEventBus(BusEvents.AddMarginToPosition).on(
        (p) => position.value = p
      );
    });
    const {
      valueToBigNumber: availableMargin,
      valueToFixed: availableMarginToFixed,
      valueToString: availableMarginToString
    } = useBigNumberFormatter(quoteBalance, {
      decimalPlaces: UI_DEFAULT_PRICE_DISPLAY_DECIMALS
    });
    const {
      value: amountValue,
      errors: amountErrors,
      setValue: setAmountValue
    } = useStringField({
      name: "amount",
      rule: "required|positiveNumber",
      dynamicRule: computed(() => `between:0.001,${availableMarginToFixed.value}`)
    });
    function handleMax() {
      setAmountValue(availableMarginToFixed.value);
    }
    function handleModalClose() {
      position.value = void 0;
      modalStore.closeModal(Modal.AddMarginToPosition);
    }
    const handleFormSubmit = handleSubmit(() => {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.addMarginToPosition({
        market: market.value,
        amount: new BigNumberInBase(amountValue.value)
      }).then(() => {
        resetForm();
        success({ title: t("trade.success_added_margin") });
        handleModalClose();
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_AppInputNumeric = __nuxt_component_2;
      const _component_AppModal = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "onModal:closed": handleModalClose
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("trade.add_margin_to_position_title")), 1)
        ]),
        default: _withCtx(() => [
          _unref(market) && _unref(quoteBalance) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("trade.availableMargin")), 1),
                    _createVNode(_component_CommonInfoTooltip, {
                      class: "ml-2 text-gray-200",
                      tooltip: _ctx.$t("trade.availableMarginTooltip")
                    }, null, 8, ["tooltip"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_unref(availableMarginToString)) + " ", 1),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(market).quoteToken.symbol), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_AppInputNumeric, {
                        modelValue: _unref(amountValue),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(amountValue) ? amountValue.value = $event : null),
                        label: _ctx.$t("trade.amount"),
                        max: _unref(availableMarginToString),
                        "max-selector": _unref(availableMargin).gt(0.01),
                        placeholder: _ctx.$t("trade.enter_your_amount"),
                        errors: _unref(status).isLoading() ? [] : _unref(amountErrors),
                        class: "no-shadow",
                        step: "0.001",
                        "data-cy": "add-margin-modal-amount-input"
                      }, {
                        max: _withCtx(() => [
                          _createVNode(_component_AppButton, {
                            xs: "",
                            class: "bg-blue-500 text-blue-900",
                            onClick: handleMax
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("trade.max")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        addon: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(market).quoteToken.symbol), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label", "max", "max-selector", "placeholder", "errors"]),
                      _unref(amountErrors).length > 0 ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_unref(amountErrors)[0]), 1)) : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_AppButton, {
                        lg: "",
                        class: "w-full bg-blue-500 text-blue-900",
                        status: _unref(status),
                        disabled: _unref(amountErrors).length > 0,
                        "data-cy": "add-margin-modal-execute-button",
                        onClick: _unref(handleFormSubmit)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("trade.add_margin")), 1)
                        ]),
                        _: 1
                      }, 8, ["status", "disabled", "onClick"])
                    ])
                  ])
                ])
              ])
            ])
          ])) : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
