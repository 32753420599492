import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Derivatives/Trading/Form.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Derivatives/Orders.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Modals/AddMargin.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Modals/MarketExpired.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Trading/Layout.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
import {
  MarketType
} from "@injectivelabs/sdk-ui-ts";
import { Status, StatusType } from "@injectivelabs/utils";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "[futures]",
  setup(__props) {
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const filterByCurrentMarket = ref(false);
    const market = ref(void 0);
    const marketIsExpired = ref(false);
    const fetchStatus = reactive(new Status(StatusType.Loading));
    onWalletConnected(() => {
      filterByCurrentMarket.value = false;
      refreshSubaccountDetails();
    });
    function onLoad(pageMarket) {
      filterByCurrentMarket.value = false;
      const derivativeMarket = pageMarket;
      Promise.all([
        derivativeStore.streamMarketsMarkPrices(),
        derivativeStore.getMarketMarkPrice(derivativeMarket),
        derivativeStore.streamTrades(derivativeMarket.marketId),
        derivativeStore.streamOrderbookUpdate(derivativeMarket.marketId)
      ]).catch($onError);
      market.value = derivativeMarket;
      refreshSubaccountDetails();
      checkMarketIsExpired(derivativeMarket);
    }
    function checkMarketIsExpired(market2) {
      if (market2.subType !== MarketType.Futures) {
        return false;
      }
      const expiryFuturesMarket = market2;
      if (!expiryFuturesMarket.expiryFuturesMarketInfo) {
        return false;
      }
      marketIsExpired.value = expiryFuturesMarket.expiryFuturesMarketInfo.expirationTimestamp <= Date.now() / 1e3;
      if (marketIsExpired) {
        modalStore.openModal({ type: Modal.MarketExpired });
      }
    }
    function refreshSubaccountDetails() {
      if (!market.value) {
        return;
      }
      derivativeStore.cancelSubaccountStream();
      const fetchOptions = filterByCurrentMarket.value ? {
        filters: {
          marketIds: [market.value.marketId]
        }
      } : void 0;
      const marketId = filterByCurrentMarket.value ? market.value.marketId : void 0;
      fetchSubaccountOrderDetails(fetchOptions);
      streamSubaccountOrderDetails(marketId);
    }
    function fetchSubaccountOrderDetails(fetchOptions) {
      var _a;
      fetchStatus.setLoading();
      const marketIds = (_a = fetchOptions == null ? void 0 : fetchOptions.filters) == null ? void 0 : _a.marketIds;
      Promise.all([
        derivativeStore.fetchSubaccountOrders(marketIds),
        derivativeStore.fetchSubaccountTrades(fetchOptions),
        positionStore.fetchSubaccountPositions(fetchOptions),
        derivativeStore.fetchSubaccountOrderHistory(fetchOptions),
        derivativeStore.fetchSubaccountConditionalOrders(marketIds)
      ]).catch($onError).finally(() => fetchStatus.setIdle());
    }
    function streamSubaccountOrderDetails(marketId) {
      Promise.all([
        derivativeStore.streamSubaccountOrders(marketId),
        derivativeStore.streamSubaccountTrades(marketId),
        positionStore.streamSubaccountPositions(marketId),
        derivativeStore.streamSubaccountOrderHistory(marketId)
      ]);
    }
    watch(
      () => walletStore.isUserWalletConnected,
      (isConnected) => {
        if (isConnected) {
          fetchStatus.setLoading();
        }
      }
    );
    return (_ctx, _cache) => {
      const _component_PartialsTradingDerivativesTradingForm = __nuxt_component_0;
      const _component_PartialsTradingDerivativesOrders = __nuxt_component_1;
      const _component_ModalsAddMargin = __nuxt_component_2;
      const _component_ModalsMarketExpired = __nuxt_component_3;
      const _component_PartialsTradingLayout = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_PartialsTradingLayout, { onLoaded: onLoad }, {
        "trading-form": _withCtx(() => [
          _unref(market) ? (_openBlock(), _createBlock(_component_PartialsTradingDerivativesTradingForm, {
            key: 0,
            market: _unref(market)
          }, null, 8, ["market"])) : _createCommentVNode("", true)
        ]),
        orders: _withCtx(() => [
          _unref(market) ? (_openBlock(), _createBlock(_component_PartialsTradingDerivativesOrders, {
            key: 0,
            filterByCurrentMarket: _unref(filterByCurrentMarket),
            "onUpdate:filterByCurrentMarket": [
              _cache[0] || (_cache[0] = ($event) => _isRef(filterByCurrentMarket) ? filterByCurrentMarket.value = $event : null),
              refreshSubaccountDetails
            ],
            market: _unref(market),
            status: _unref(fetchStatus)
          }, null, 8, ["filterByCurrentMarket", "market", "status"])) : _createCommentVNode("", true)
        ]),
        modals: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ModalsAddMargin),
            _unref(market) && _unref(marketIsExpired) ? (_openBlock(), _createBlock(_component_ModalsMarketExpired, {
              key: 0,
              market: _unref(market)
            }, null, 8, ["market"])) : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      });
    };
  }
});
