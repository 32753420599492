import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "text-base" };
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between mt-4"
};
const _hoisted_3 = { class: "flex items-center" };
const _hoisted_4 = { class: "flex flex-col" };
const _hoisted_5 = {
  "data-cy": "market-card-ticker-text-content",
  class: "uppercase tracking-widest text-sm font-bold leading-4"
};
const _hoisted_6 = { class: "text-xs text-gray-500 capitalize" };
const _hoisted_7 = { class: "text-sm text-gray-100 mt-4" };
const _hoisted_8 = { class: "text-sm text-gray-100 mt-2" };
const _hoisted_9 = { class: "mt-6 flex items-center justify-center gap-2" };
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketExpired",
  props: {
    market: {
      type: Object,
      default: void 0
    }
  },
  setup(__props) {
    const props = __props;
    const modalStore = useModalStore();
    const router = useRouter();
    const isModalOpen = computed(
      () => modalStore.modals[Modal.MarketExpired] && props.market
    );
    function close() {
      modalStore.closeModal(Modal.MarketExpired);
      router.push({ name: "markets" });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      const _component_AppModal = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "hide-close-button": "",
        "onModal:closed": close
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("marketExpired.title")), 1)
        ]),
        default: _withCtx(() => [
          __props.market ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              __props.market.baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                key: 0,
                lg: "",
                token: __props.market.baseToken
              }, null, 8, ["token"])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(__props.market.ticker), 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(__props.market.baseToken.name), 1)
              ])
            ]),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("marketExpired.expiredNote")), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("marketExpired.activityPageNote")), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_NuxtLink, {
                to: { name: "markets" },
                class: "flex items-center justify-center px-4 py-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, { class: "bg-blue-500 text-blue-900 font-semibold" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("marketExpired.exploreMarkets")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NuxtLink, {
                to: { name: "activity" },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, { class: "border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-blue-900" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("marketExpired.goToActivity")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])) : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
