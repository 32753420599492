import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/Position/Mobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Common/Subaccount/Position/Header.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/Position/Row.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "h-full" };
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import {
  MarketType
} from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const positionStore = usePositionStore();
    const filteredPositions = computed(() => {
      const result = positionStore.subaccountPositions.filter((position) => {
        return !!derivativeStore.markets.find(
          (m) => m.marketId === position.marketId
        );
      });
      if (props.market.subType === MarketType.BinaryOptions) {
        return result.filter(
          (position) => derivativeStore.binaryOptionsMarkets.some(
            (market) => market.marketId === position.marketId
          )
        );
      }
      return result;
    });
    const sortedPositions = computed(() => {
      return [...filteredPositions.value].sort((p1, p2) => {
        return p1.ticker.localeCompare(p2.ticker);
      });
    });
    onMounted(() => {
      positionStore.fetchOpenPositionsMarketsOrderbook();
    });
    useIntervalFn(() => {
      positionStore.fetchOpenPositionsMarketsOrderbook();
    }, 10 * 1e3);
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountPositionMobile = __nuxt_component_0;
      const _component_CommonEmptyList = __nuxt_component_1;
      const _component_CommonTableBody = __nuxt_component_2;
      const _component_PartialsCommonSubaccountPositionHeader = __nuxt_component_3;
      const _component_PartialsCommonSubaccountPositionRow = __nuxt_component_4;
      const _component_CommonTableWrapper = __nuxt_component_5;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CommonTableBody, {
          "show-empty": _unref(sortedPositions).length === 0,
          class: "sm:hidden max-h-lg"
        }, {
          empty: _withCtx(() => [
            _createVNode(_component_CommonEmptyList, {
              message: _ctx.$t("trade.emptyPositions"),
              class: "min-h-orders bg-gray-900"
            }, null, 8, ["message"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedPositions), (position, index) => {
              return _openBlock(), _createBlock(_component_PartialsCommonSubaccountPositionMobile, {
                key: `mobile-positions-${index}-${position.marketId}`,
                class: "col-span-1",
                position
              }, null, 8, ["position"]);
            }), 128))
          ]),
          _: 1
        }, 8, ["show-empty"]),
        _createVNode(_component_CommonTableWrapper, { class: "hidden sm:block" }, {
          default: _withCtx(() => [
            _unref(sortedPositions).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
              _createVNode(_component_PartialsCommonSubaccountPositionHeader),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedPositions), (position, index) => {
                  return _openBlock(), _createBlock(_component_PartialsCommonSubaccountPositionRow, {
                    key: `positions-${index}-${position.marketId}`,
                    position
                  }, null, 8, ["position"]);
                }), 128))
              ])
            ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
              key: 1,
              message: _ctx.$t("trade.emptyPositions")
            }, null, 8, ["message"]))
          ]),
          _: 1
        })
      ]);
    };
  }
});
