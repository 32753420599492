import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/Separator.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Checkbox.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Common/Subaccount/Order/Index.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Partials/Common/Subaccount/Trigger/Index.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/Partials/Common/Subaccount/OrderHistory/Index.vue";
import { default as __nuxt_component_8 } from "/opt/build/repo/components/Partials/Common/Subaccount/Position/Index.vue";
import { default as __nuxt_component_9 } from "/opt/build/repo/components/Common/Card/Index.vue";
import { default as __nuxt_component_10 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_11 } from "/opt/build/repo/components/Common/Card/TableWrap.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "col-span-12 lg:col-span-7 xl:col-span-8 m-4 lg:mx-0" };
const _hoisted_2 = { class: "flex items-center justify-between lg:justify-start gap-2 ml-2 flex-wrap" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { key: 2 };
const _hoisted_6 = { key: 3 };
const _hoisted_7 = { key: 4 };
const _hoisted_8 = { class: "col-span-12 lg:col-span-5 xl:col-span-4 mx-4 mb-4 flex items-center justify-between lg:justify-end lg:ml-0 lg:mr-2 lg:mt-4" };
import { Status, StatusType } from "@injectivelabs/utils";
import {
  MarketType
} from "@injectivelabs/sdk-ui-ts";
import { GeneralException } from "@injectivelabs/exceptions";
export default /* @__PURE__ */ _defineComponent({
  __name: "Orders",
  props: {
    filterByCurrentMarket: Boolean,
    market: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: true
    }
  },
  emits: ["update:filterByCurrentMarket"],
  setup(__props, { emit }) {
    const props = __props;
    const FilterList = {
      OpenPositions: "OpenPositions",
      OpenOrders: "OpenOrders",
      Triggers: "Triggers",
      OrderHistory: "OrderHistory",
      TradeHistory: "TradeHistory"
    };
    const derivativeStore = useDerivativeStore();
    const positionStore = usePositionStore();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const { success } = useNotifications();
    const actionStatus = reactive(new Status(StatusType.Idle));
    const activeType = ref(FilterList.OpenOrders);
    const filteredConditionalOrders = computed(() => {
      return derivativeStore.subaccountConditionalOrders.filter((order) => {
        if (props.market.subType !== MarketType.BinaryOptions) {
          return derivativeStore.markets.some(
            (market) => market.marketId === order.marketId
          );
        }
        return derivativeStore.binaryOptionsMarkets.some(
          (market) => market.marketId === order.marketId
        );
      });
    });
    const filteredOrders = computed(() => {
      return derivativeStore.subaccountOrders.filter((order) => {
        if (props.market.subType !== MarketType.BinaryOptions) {
          return derivativeStore.markets.some(
            (market) => market.marketId === order.marketId
          );
        }
        return derivativeStore.binaryOptionsMarkets.some(
          (market) => market.marketId === order.marketId
        );
      });
    });
    const filteredPositions = computed(() => {
      const result = positionStore.subaccountPositions.filter((position) => {
        return !!derivativeStore.markets.find(
          (m) => m.marketId === position.marketId
        );
      });
      return result.filter((position) => {
        if (props.market.subType !== MarketType.BinaryOptions) {
          return position;
        }
        return derivativeStore.binaryOptionsMarkets.some(
          (market) => market.marketId === position.marketId
        );
      });
    });
    const orders = computed(
      () => activeType.value === FilterList.OpenOrders ? derivativeStore.subaccountOrders : filteredOrders.value
    );
    const checked = computed({
      get: () => props.filterByCurrentMarket,
      set: (value) => {
        emit("update:filterByCurrentMarket", value);
      }
    });
    onMounted(() => {
      if (positionStore.subaccountPositions.length > 0) {
        activeType.value = FilterList.OpenPositions;
      } else if (derivativeStore.subaccountOrders.length > 0) {
        activeType.value = FilterList.OpenOrders;
      }
    });
    function handleCancelAllClick() {
      actionStatus.setLoading();
      const action = orders.value.length === 1 ? derivativeStore.cancelOrder(orders.value[0]) : derivativeStore.batchCancelOrder(orders.value);
      action.then(() => {
        success({ title: t("trade.orders_cancelled") });
      }).catch($onError).finally(() => {
        actionStatus.setIdle();
      });
    }
    function closeAllPositions() {
      return positionStore.closeAllPosition(filteredPositions.value);
    }
    function closePosition() {
      const [position] = filteredPositions.value;
      const market = derivativeStore.markets.find(
        (m) => m.marketId === position.marketId
      );
      if (!market) {
        return Promise.reject(
          new GeneralException(
            Error(
              t("trade.position_market_not_found", {
                marketId: position.marketId
              })
            )
          )
        );
      }
      return positionStore.closePosition({
        position,
        market
      });
    }
    function handleCloseAllPositionsClick() {
      actionStatus.setLoading();
      const action = filteredPositions.value.length === 1 ? closePosition : closeAllPositions;
      action().then(() => {
        success({ title: t("trade.positions_closed") });
      }).catch($onError).finally(() => {
        actionStatus.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      const _component_CommonSeparator = __nuxt_component_1;
      const _component_AppCheckbox = __nuxt_component_2;
      const _component_AppButton = __nuxt_component_3;
      const _component_PartialsCommonSubaccountTradeHistory = __nuxt_component_4;
      const _component_PartialsCommonSubaccountOrder = __nuxt_component_5;
      const _component_PartialsCommonSubaccountTrigger = __nuxt_component_6;
      const _component_PartialsCommonSubaccountOrderHistory = __nuxt_component_7;
      const _component_PartialsCommonSubaccountPosition = __nuxt_component_8;
      const _component_CommonCard = __nuxt_component_9;
      const _component_AppHocLoading = __nuxt_component_10;
      const _component_CommonCardTableWrap = __nuxt_component_11;
      return _openBlock(), _createBlock(_component_CommonCardTableWrap, null, {
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (filterType, index) => {
                return _openBlock(), _createElementBlock(_Fragment, {
                  key: `derivative-orders-type-${filterType}`
                }, [
                  _createVNode(_component_AppSelectButton, {
                    modelValue: _unref(activeType),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
                    value: filterType
                  }, {
                    default: _withCtx(({ active }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass(["uppercase text-xs font-semibold whitespace-nowrap overflow-ellipsis", [active ? "text-blue-500" : "text-gray-500"]])
                      }, [
                        filterType === FilterList.OpenPositions ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("activity.openPositions")) + " " + _toDisplayString(`(${_unref(filteredPositions).length})`), 1)) : _createCommentVNode("", true),
                        filterType === FilterList.OpenOrders ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("activity.openOrders")) + " " + _toDisplayString(`(${_unref(filteredOrders).length})`), 1)) : _createCommentVNode("", true),
                        filterType === FilterList.Triggers ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("activity.triggers")) + " " + _toDisplayString(`(${_unref(filteredConditionalOrders).length})`), 1)) : _createCommentVNode("", true),
                        filterType === FilterList.TradeHistory ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("activity.tradeHistory")), 1)) : _createCommentVNode("", true),
                        filterType === FilterList.OrderHistory ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("activity.orderHistory")), 1)) : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "value"]),
                  index !== Object.values(FilterList).length - 1 ? (_openBlock(), _createBlock(_component_CommonSeparator, { key: 0 })) : _createCommentVNode("", true)
                ], 64);
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            __props.market ? (_openBlock(), _createBlock(_component_AppCheckbox, {
              key: 0,
              modelValue: _unref(checked),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(checked) ? checked.value = $event : null),
              "data-cy": "trade-page-filter-by-ticker-checkbox",
              class: "lg:mr-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("trade.asset_only", { asset: __props.market.ticker })), 1)
              ]),
              _: 1
            }, 8, ["modelValue"])) : _createCommentVNode("", true),
            [FilterList.OpenOrders, FilterList.Triggers].includes(_unref(activeType)) && _unref(orders).length > 0 ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 1,
              class: "bg-red-500 bg-opacity-10 text-red-500 hover:text-white",
              xs: "",
              status: _unref(actionStatus),
              "data-cy": "trade-page-cancel-all-button",
              onClick: handleCancelAllClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("trade.cancelAllOrders")), 1)
              ]),
              _: 1
            }, 8, ["status"])) : _createCommentVNode("", true),
            _unref(activeType) === FilterList.OpenPositions && _unref(filteredPositions).length > 0 ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 2,
              xs: "",
              status: _unref(actionStatus),
              "data-cy": "trade-page-cancel-all-button",
              class: "bg-red-500 bg-opacity-10 text-red-500 hover:text-white",
              onClick: handleCloseAllPositionsClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("trade.closeAllPositions")), 1)
              ]),
              _: 1
            }, 8, ["status"])) : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_AppHocLoading, {
            class: "h-full",
            status: __props.status
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CommonCard, { class: "h-full" }, {
                default: _withCtx(() => [
                  _unref(activeType) === FilterList.TradeHistory ? (_openBlock(), _createBlock(_component_PartialsCommonSubaccountTradeHistory, {
                    key: 0,
                    market: __props.market
                  }, null, 8, ["market"])) : _unref(activeType) === FilterList.OpenOrders ? (_openBlock(), _createBlock(_component_PartialsCommonSubaccountOrder, {
                    key: 1,
                    market: __props.market
                  }, null, 8, ["market"])) : _unref(activeType) === FilterList.Triggers ? (_openBlock(), _createBlock(_component_PartialsCommonSubaccountTrigger, { key: 2 })) : _unref(activeType) === FilterList.OrderHistory ? (_openBlock(), _createBlock(_component_PartialsCommonSubaccountOrderHistory, {
                    key: 3,
                    market: __props.market
                  }, null, 8, ["market"])) : _unref(activeType) === FilterList.OpenPositions ? (_openBlock(), _createBlock(_component_PartialsCommonSubaccountPosition, {
                    key: 4,
                    market: __props.market
                  }, null, 8, ["market"])) : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["status"])
        ]),
        _: 1
      });
    };
  }
});
